import React from "react";
import { navigate } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import LayoutExamples from "../../layouts/LayoutExamples";
import { Headline1Sans } from "../../system42";
import * as styles from "./styles.module.css";
import { ExampleList } from "../../components/ExampleList";
import { accessibleOnClick } from "@system42/core";

export default function ExampleTaskType(props) {
  const { data, pageContext } = props;

  const examples = data.allStrapiExample.edges.map((edge) => edge.node);

  function handleClickAllExamples() {
    navigate(`/user-testing-template-library/`);
  }

  return (
    <LayoutExamples activeDeviceId={pageContext.exampleDevice}>
      <button
        className={styles.buttonAllExamples}
        {...accessibleOnClick(handleClickAllExamples)}
      >
        All examples
      </button>
      <Headline1Sans customTag={"h2"} className={styles.title}>
        {pageContext.exampleDevice}
      </Headline1Sans>

      <ExampleList
        context={`library_devices_${pageContext.exampleDevice}`}
        examples={examples}
      />
    </LayoutExamples>
  );
}

export const query = graphql`
  query exampleDevice($exampleDevice: String!) {
    allStrapiExample(
      filter: { online: { eq: true }, device: { eq: $exampleDevice } }
    ) {
      edges {
        node {
          id
          url
          title
          type
          device
          companyName
          cardTitleWhite
          taskTypes {
            title
            url
          }
          categories {
            title
            url
          }
          cardColor
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
